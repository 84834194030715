import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Nullable, Optional } from '@src/types/utils';
import {
  CalculateProductCostDto,
  FileInfoBase,
  LoyaltyDigestSendTo,
  LoyaltyProductCode,
  LoyaltyProductWCategiriesEdit,
  LoyaltyService,
  SendContactsRequestDto,
} from '@src/api';
import { AlertService, PhotoService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable, catchError, lastValueFrom, throwError, of, map } from 'rxjs';

import { ConfirmDialogComponent } from '../ui';
import {
  LoyaltyProgramGrade,
  LoyaltyProgramProduct,
  LoyaltyProgramProductImage,
  LoyaltyProgramProductInput,
} from '../models';

import { LOYALTY_GRADES_MAP } from './constants';
import { convertFromProductInput } from './utils/convertFromProductInput';
import { convertToProduct } from './utils/convertToProduct';
import { LoyaltyRating, QrCodeImageFile, QrCodeImageIndexId, UpdateQrCodeImagesResult } from './types';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramApiService implements OnDestroy {
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly api: LoyaltyService,
    private readonly alertService: AlertService,
    private readonly photoService: PhotoService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async getProductById(id: string, unionId?: string) {
    try {
      const result = await lastValueFrom(this.api.loyaltyProduct(id, unionId));
      if (result) {
        return convertToProduct(result);
      }
    } catch (err) {
      this.showError(this.translateService.instant('components.loyaltyProgram.alerts.errors.loyaltyProduct'), err);
    }

    return;
  }

  async createProduct(product: LoyaltyProgramProductInput): Promise<string | void> {
    let newImages: FileInfoBase[] = [];

    // Массив идентификаторов изображений QR кодов, которые загрузил пользователь сам
    let qrCodeImages: QrCodeImageIndexId = [];

    try {
      newImages = await this.uploadImages(product.images, undefined, product.unionId);
      qrCodeImages = await this.createQrCodeImages(product);

      const { id } = await lastValueFrom(
        this.api.addLoyaltyProduct(
          convertFromProductInput({
            product,
            imagesIds: newImages.map(item => item.id!),
            coverImageId: this.getCoverImageId(product, newImages),
            qrCodeImages,
          }),
        ),
      );

      if (!id) {
        this.deleteImages(newImages);
        this.deleteImages(qrCodeImages);
        this.alertService.error(
          this.translateService.instant('components.loyaltyProgram.alerts.errors.addLoyaltyProduct'),
        );
        return;
      }

      return id;
    } catch (err) {
      this.deleteImages(newImages);
      this.deleteImages(qrCodeImages);
      this.showError(this.translateService.instant('components.loyaltyProgram.alerts.errors.addLoyaltyProduct'), err);
      throw err;
    }
  }

  async updateProduct(product: LoyaltyProgramProductInput, origin?: LoyaltyProgramProduct): Promise<string | void> {
    if (!origin?.id) {
      return;
    }

    // Список картинок, которые были ранее загружены
    const existsImageIds = product.images?.filter(item => !!item.id).map(item => item.id!) ?? [];
    // Новые, которые хотим загрузить
    const newImageIds = product.images?.filter(item => !item.id) ?? [];
    // Список, который нужно удалить, т.к. пользователь их удалил при редактировании
    const imageIdsToDelete = origin.images
      ?.map<string>(item => item.id!)
      .filter(imageId => !existsImageIds.includes(imageId));

    let newImages: FileInfoBase[] = [];
    let newQrCodeImages: FileInfoBase[] = [];

    try {
      // Грузим новые картинки без обложки
      newImages = await this.uploadImages(newImageIds, false, product.unionId);
      const updateResult = await this.updateQrCodeImages(product, origin);

      newQrCodeImages = updateResult.newQrCodeImages;

      const payload: LoyaltyProductWCategiriesEdit = {
        ...convertFromProductInput({
          product,
          imagesIds: [...existsImageIds, ...newImages.map(item => item.id!)],
          coverImageId: this.getCoverImageId(product, newImages),
          qrCodeImages: updateResult.qrCodeImages,
        }),
        id: origin.id,
      };

      const { id } = await lastValueFrom(this.api.editLoyaltyProduct(payload));
      if (!id) {
        this.deleteImages(newImages);
        this.deleteImages(newQrCodeImages);
        this.alertService.error(
          this.translateService.instant('components.loyaltyProgram.alerts.errors.editLoyaltyProduct'),
        );
        return;
      }

      await Promise.all([this.deleteImages(imageIdsToDelete), this.deleteImages(updateResult.qrCodeImageIdsToDelete)]);

      return id;
    } catch (err) {
      this.deleteImages(newImages);
      this.deleteImages(newQrCodeImages);
      this.showError(this.translateService.instant('components.loyaltyProgram.alerts.errors.editLoyaltyProduct'), err);
      throw err;
    }
  }

  deleteProduct(id?: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!id) {
        return resolve(false);
      }

      this.dialogService
        .open<void | boolean>(new PolymorpheusComponent(ConfirmDialogComponent, this.injector), {
          label: this.translateService.instant('common.dialogs.deletingEntryHeader'),
          data: this.translateService.instant('common.dialogs.deletingProductConfirmLabel'),
        })
        .subscribe(result => {
          if (result) {
            lastValueFrom(this.api.removeLoyaltyProduct(id))
              .then(() => {
                resolve(true);
              })
              .catch(err => {
                this.showError(
                  this.translateService.instant('components.loyaltyProgram.alerts.errors.removeLoyaltyProduct'),
                  err,
                );
                resolve(false);
              });
          } else {
            resolve(false);
          }
        });
    });
  }

  async publishProduct(id?: string): Promise<boolean> {
    if (!id) {
      return false;
    }

    try {
      await lastValueFrom(this.api.activateLoyaltyProduct(id));
    } catch (err) {
      this.showError(
        this.translateService.instant('components.loyaltyProgram.alerts.errors.activateLoyaltyProduct'),
        err,
      );
      return false;
    }

    return true;
  }

  async unpublishProduct(id?: string): Promise<boolean> {
    if (!id) {
      return false;
    }

    try {
      await lastValueFrom(this.api.deactivateLoyaltyProduct(id));
    } catch (err) {
      this.showError(
        this.translateService.instant('components.loyaltyProgram.alerts.errors.deactivateLoyaltyProduct'),
        err,
      );
      return false;
    }

    return true;
  }

  getProductCode(productId: string, unionId?: string): Observable<LoyaltyProductCode[]> {
    return this.api.loyaltyProductCode(productId, unionId).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  async calculateGrade(imagesCount: number, descriptionLength: number): Promise<Nullable<LoyaltyProgramGrade>> {
    try {
      const rating = await lastValueFrom(this.api.productRating(imagesCount, descriptionLength));

      return LOYALTY_GRADES_MAP[rating as LoyaltyRating];
    } catch {
      return null;
    }
  }

  saveRedirectLink(productId: string, link?: string, unionId?: string) {
    this.api.saveRedirectLink(productId, link, unionId).subscribe();
  }

  sendDigest(data: LoyaltyDigestSendTo = {}) {
    return this.api.sendDigest(data);
  }

  digestTest() {
    return this.api.digestTest();
  }

  getBoostRates(unionId?: string) {
    return this.api.getBoostRates(unionId).pipe(
      map(items => items.sort((a, b) => (a?.value ?? 0) - (b?.value ?? 0))),
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  getProductCost(data: CalculateProductCostDto) {
    return this.api.getProductCost(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  getCostOptions(productId: string, unionId?: string) {
    return this.api.getLoyaltyProductOptions(productId, unionId).pipe(
      catchError(err => {
        if (err.status === 404) {
          return of(null);
        }

        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  sendContacts(data: SendContactsRequestDto) {
    return this.api.sendContacts(data).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  getCurrentTariff(unionId?: string) {
    return this.api.getCurrentTariff(unionId).pipe(
      catchError(err => {
        this.alertService.error(err);
        return throwError(() => err);
      }),
    );
  }

  private showError(title: string, err: unknown) {
    const message = err instanceof Error ? err.message : err;
    this.alertService.error(`${title}\n${message}`);
  }

  private getCoverImageId(product: LoyaltyProgramProductInput, newImages: FileInfoBase[]): Optional<string> {
    const coverImage = product.images?.find(item => item.isCover);

    if (coverImage?.id) {
      // Уже существующая запись
      return coverImage.id;
    } else if (coverImage?.file instanceof File) {
      // Надо найти по имени файла id из вновь созданных
      return newImages.find(item => item.fileName === (coverImage.file as File).name)?.id;
    }

    return undefined;
  }

  private async deleteImages(images?: Array<undefined | string | FileInfoBase>) {
    await Promise.all(
      images
        ?.filter(item => !!item)
        .forEach(image => {
          return lastValueFrom(this.photoService.deletePhoto(typeof image === 'string' ? image : image?.id!));
        }) ?? [],
    );
  }

  /**
   * Функция загрузки файлов, которые относятся к нашему продукту
   * @param images набор файлов, которые были заполнены на форме
   * @param isAutoCover флаг, что автоматически обновляем обложку
   */
  private async uploadImages(
    images?: LoyaltyProgramProductImage[],
    isAutoCover = true,
    unionId?: string,
  ): Promise<FileInfoBase[]> {
    if (!(images && images.length)) {
      return [];
    }

    // Новые файлы, которые пользователь загрузил
    let newFiles: File[] = [];
    let coverFile: Optional<File>;

    images.forEach(item => {
      if (item.file instanceof File) {
        if (item.isCover && isAutoCover) {
          coverFile = item.file;
        } else {
          newFiles.push(item.file);
        }
      }
    });

    if (!(newFiles.length || coverFile)) {
      return [];
    }

    if (coverFile) {
      newFiles = [coverFile, ...newFiles];
    }

    const { files = [] } = await lastValueFrom(
      this.photoService.uploadFiles(newFiles, undefined, isAutoCover, undefined, unionId),
    );

    return files;
  }

  private getQrCodeImageIndexIds(product: LoyaltyProgramProductInput): [QrCodeImageIndexId, QrCodeImageFile[]] {
    const result: QrCodeImageIndexId = [];
    const newFiles: QrCodeImageFile[] = [];

    product.terms.forEach((term, index) => {
      const { qrCodeImage } = term;

      if (qrCodeImage?.id) {
        result[index] = qrCodeImage.id;
      } else if (qrCodeImage?.file instanceof File) {
        newFiles.push({
          file: qrCodeImage.file,
          index,
        });
      }
    });

    return [result, newFiles];
  }

  private async createQrCodeImages(product: LoyaltyProgramProductInput): Promise<QrCodeImageIndexId> {
    const [result, newFiles] = this.getQrCodeImageIndexIds(product);

    if (newFiles.length) {
      const { files = [] } = await lastValueFrom(
        this.photoService.uploadFiles(
          newFiles.map(item => item.file),
          undefined,
          false,
          undefined,
          product.unionId,
        ),
      );

      files.forEach(({ id }, index) => {
        result[newFiles[index].index] = id;
      });
    }

    return result;
  }

  private async updateQrCodeImages(
    product: LoyaltyProgramProductInput,
    origin: LoyaltyProgramProductInput,
  ): Promise<UpdateQrCodeImagesResult> {
    const [qrCodeImages, newFiles] = this.getQrCodeImageIndexIds(product);

    let newQrCodeImages: FileInfoBase[] = [];

    if (newFiles.length) {
      const { files = [] } = await lastValueFrom(
        this.photoService.uploadFiles(
          newFiles.map(item => item.file),
          undefined,
          false,
          undefined,
          product.unionId,
        ),
      );

      newQrCodeImages = files;
      files.forEach(({ id }, index) => {
        qrCodeImages[newFiles[index].index] = id;
      });
    }

    // Вычисляем идентификаторы, которые нужно удалить
    const qrCodeImageIdsToDelete: string[] = [];

    origin.terms.forEach(term => {
      const qrCodeImageId = term.qrCodeImage?.id;

      if (qrCodeImageId && !qrCodeImages.includes(qrCodeImageId)) {
        qrCodeImageIdsToDelete.push(qrCodeImageId);
      }
    });

    return {
      qrCodeImageIdsToDelete,
      qrCodeImages,
      newQrCodeImages,
    };
  }
}
